import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Stack, Typography } from '@mui/material';
import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import PageLink from '@/components/page/link';

export default function FAQSection() {
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'
			sx={{ px: { xs: 3, md: 10, lg: 15, xl: 40 }, py: { xs: 3, sm: 20 } }}>
			<Typography gutterBottom className='sectionTitle' textAlign='center'>
				FAQ
			</Typography>
			<Typography className='sectionSubTitle' textAlign='center' mb={5}>
				Have questions? Well, We have answers!
			</Typography>
			<LayoutAnimationStaggerParent>
				<Stack
					spacing={3}
					sx={{
						'.MuiAccordion-root': {
							'borderRadius'             : '10px !important',
							':before'                  : { display: 'none' },
							'boxShadow'                : 0,
							'border'                   : 0,
							'svg'                      : {
								color     : 'primary.main',
								fontWeight: 'bold',
								fontSize  : '35px !important',
							},
							'.MuiAccordionSummary-root': {
								py: 1,
								px: { xs: 2, sm: 5 },
							},
							'.MuiAccordionDetails-root': {
								pt: 1,
								pb: 2,
								px: { xs: 3, sm: 5 },
							},
						},
					}}>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Accordion sx={{ borderRadius: 5 }}>
							<AccordionSummary expandIcon={( <ExpandMoreIcon/> )}>
								<Typography className='faqTitle'>How does Invoiss process a payment
									online?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									The Invoiss platform serves as a mediator for processing credit card transactions via tokens
									from your chosen Payment Gateway—be it Clover, CardConnect, Stripe, or Square. All
									transactions can be accessed directly from your point of sale.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
								<Typography className='faqTitle'>Does Invoiss sync my data to my Clover account?</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									Certainly, you have the option to automatically sync your invoices, clients, items, payments,
									stock counts, taxes, and fees with Clover, or do it manually with just a click.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
								<Typography className='faqTitle'>Do I need to manually sync my clover clients and
									items?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									No, Invoiss will automatically sync every change you make on your clover account.
									So, for instance, if you create an item or add a new customer, Invoiss will also
									display
									that.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
								<Typography className='faqTitle'>How does Invoiss save me card processing fee?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									Invoiss automatically applies a fee to the invoice when it is settled using a credit or,
									optionally, a debit card.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion sx={{ borderRadius: 5 }}>
							<AccordionSummary expandIcon={( <ExpandMoreIcon/> )}>
								<Typography className='faqTitle'>What's house account? and how to use it?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									A House Account enables your customers to acquire your products and services without
									immediate payment. At the end of each billing cycle, an invoice is generated for the
									account holder.
									<PageLink
										href='/articles/guides/house-account#intro'
										underline='hover'
										sx={{ display: 'inline-block' }}>
										<Typography fontWeight='500'>
											Learn more
										</Typography>
									</PageLink>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion sx={{ borderRadius: 5 }}>
							<AccordionSummary expandIcon={( <ExpandMoreIcon/> )}>
								<Typography className='faqTitle'>What's Cash Discount? and how to use it?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									Invoiss will mark up all your products prices automatically and instead add a discount if the
									payment method is free of
									charge to you. (Cash, check, or bank transfer). The cash discount is a great way to encourage
									your client to pay their invoices with
									cash or check to avoid paying card processing fees.<PageLink
									href='/articles/guides/cash-discount'
									underline='hover'
									sx={{ display: 'inline-block' }}>
										<Typography fontWeight='500'>
											Learn more
										</Typography>
									</PageLink>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
					<LayoutAnimation hasStagger variant='slideUp'>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
								<Typography className='faqTitle'>What clover devices does Invoiss
									supports?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className='faqContent'>
									Invoiss supports almost all clover devices.
									<ul>
										<li>Clover Solo</li>
										<li>Clover Duo</li>
										<li>Clover Stations</li>
										<li>Clover Flex 2 & 3</li>
										<li>Clover Mini 2 & 3</li>
									</ul>
									Invoiss is also a mobile and desktop app which you can install on your computer and
									phone.
								</Typography>
							</AccordionDetails>
						</Accordion>
					</LayoutAnimation>
				</Stack>
			</LayoutAnimationStaggerParent>
		</Container>
	);
}
