import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';
import { slideLeft } from './accordionSection';

export default function AccordionHouseAccountFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/house-account-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>House Accounts & Statements</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography
								gutterBottom
								className='sectionContentTitle'
								textAlign='center'>
								Invoice your routine customers weekly or monthly!
								<Typography
									component='span'
									className='sectionContentTitle'
									color='success.main'
									textAlign='center'
									sx={{ px: 2 }}>
									Convenient way for your customers to purchase your products and
									services and pay back weekly or monthly
								</Typography>
							</Typography>
						</LayoutAnimation>
						
						<Grid container spacing={15} my={{ xs: 2, md: 15 }}>
							<Grid
								item
								xs={12}
								sx={{
									display       : 'flex',
									flexDirection : 'column',
									alignItems    : 'center',
									justifyContent: 'center',
								}}>
								<LayoutAnimation hasStagger variant='slideUp'>
									<Stack mb={3} textAlign='center'>
										<Typography className='sectionContentTitle'>
											Set up house accounts and assign charges to them.
										</Typography>
										<Typography className='sectionContentSubTitle'>
											Quickly ring up an order on the register app and directly bill it to the customer's
											house account from your point of sale.
										</Typography>
									</Stack>
								</LayoutAnimation>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display       : 'flex',
									flexDirection : 'column',
									alignItems    : 'center',
									justifyContent: 'center',
								}}>
								<LayoutAnimation hasStagger variant='slideUp'>
									<Stack textAlign='center'>
										<Typography className='sectionContentTitle'>
											User your Clover device to create charges
										</Typography>
										<Typography className='sectionContentSubTitle'>
											Select the "Invoiss" payment option at checkout, and voilà!
										</Typography>
									</Stack>
								</LayoutAnimation>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display       : 'flex',
									flexDirection : 'column',
									alignItems    : 'center',
									justifyContent: 'center',
								}}>
								<Typography className='sectionContentTitle'>
									For customers with employees
								</Typography>
								<Typography className='sectionContentSubTitle'>
									We have the best solution tucked away under the hood! Generate unique digital cards that
									restrict and monitor purchases charged to a house account, providing complete details.
								</Typography>
								<LayoutAnimation hasStagger variant='slideUp'>
									<StyledImage
										alt='invoiss icon'
										src='/images/static-images/digitalcard.png'
										sx={{ height: 400 }}
									/>
								</LayoutAnimation>
								<Stack spacing={5} pt={5} alignItems='center' justifyContent='center'>
									<Typography className='sectionContentTitle'>
										Auto statement
									</Typography>
									<Typography className='sectionContentSubTitle'>
										Invoiss automatically produces your statements on a weekly or monthly basis and emails
										them to your client.
									</Typography>
								</Stack>
								<LayoutAnimation hasStagger variant='slideUp'>
									<Box sx={{ p: 2, bgcolor: '#ffffff', borderRadius: 8, boxShadow: 25, mt: 3 }}>
										<StyledImage
											alt='invoiss icon'
											src='/images/static-images/allInvoices.png'
											sx={{ width: '100%' }}
										/>
									</Box>
								</LayoutAnimation>
							</Grid>
						</Grid>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Stack spacing={1} pt={5} alignItems='center' justifyContent='center'>
								<StyledCustomButton
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									color='#280'
									target='_blank'>
									Connect Now
								</StyledCustomButton>
								<Typography className='connectTrial' textAlign='center'>
									FREE 14-days
								</Typography>
							</Stack>
						</LayoutAnimation>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
